$font-system: "Heebo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";
$font-headline: "Heebo", Impact, Arial, sans-serif;

@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: bold;
  src: local(""), url("/fonts/Heebo-Bold.woff2") format("woff2"),
    url("/fonts/Heebo-Bold.woff") format("woff");
}

@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: normal;
  src: local(""), url("/fonts/Heebo-Light.woff2") format("woff2"),
    url("/fonts/Heebo-Light.woff") format("woff");
}
