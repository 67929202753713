@import "styles/_colors.scss";
@import "styles/_fonts.scss";

@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sticker {
  background: linear-gradient($color-primary, $color-secondary);
  border: 10px solid $color-flourescent;
  box-shadow: 1px 1px 4px rgba($color-black, 0.25);
  width: 294px;
  height: 352px;
  display: flex;
  padding: 14px;
  flex-direction: column;
  color: $color-white;
  text-align: center;
  text-decoration: none;
}

.placeholder {
  background: #c4c4c4;
  border-color: #b1b1b1;
  box-shadow: none;
}

.image {
  border: 5px solid $color-white;
  width: 246px;
  height: 246px;
  background: radial-gradient(
    84.15% 84.15% at 50% 15.85%,
    #898989 0%,
    #e5e5e5 100%
  );
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

.holograph {
  background-image: url("/images/holograph.jpg");
  background-size: 100%;
  padding-top: 14px;
}

.number {
  font-family: $font-headline;
  font-size: 24px;
  font-weight: bold;
}

.name {
  @include ellipsis();
  font-family: $font-headline;
  text-transform: uppercase;
  font-size: 20px;
  margin: 3px 0 0;
  line-height: 1;
}

.title {
  @include ellipsis();
  font-weight: normal;
  font-size: 16px;
  margin: 0;
  line-height: 1.25;
}
