@import "styles/_colors";
@import "styles/_fonts";

.container {
  max-width: 1495px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1140px) {
    padding: 0 60px;
  }
}

.header {
  display: flex;
  max-width: 790px;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  @media (min-width: 1140px) {
    margin-bottom: 40px;
  }
}

.linkedin {
  width: 48px;
  height: 48px;
  display: block;
  margin-bottom: 7px;
}

.linkedin img {
  width: 100%;
}

.portfolio {
  border-top: 10px solid $color-secondary;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  position: relative;

  @media (min-width: 1140px) {
    justify-content: flex-start;
    padding: 60px 0;
    gap: 60px;
  }
}

.shiney {
  background-image: url("/images/shiney.jpg");
  background-size: cover;
  box-shadow: 1px 1px 4px rgba($color-black, 0.25);
  width: 294px;
  height: 352px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge {
  max-width: 65%;
}
