@import-normalize;
@import "styles/_colors";
@import "styles/_fonts";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background: $color-flourescent;
  color: $color-secondary;
  -webkit-text-fill-color: initial;
}

body {
  font-family: $font-system;
  margin: 30px 0;
  line-height: 150%;
  background-color: lighten($color-primary, 25%);

  @media (min-width: 1140px) {
    margin: 70px 0;
    line-height: 180%;
  }
}

h1 {
  color: $color-secondary;
  font-family: $font-headline;
  text-transform: uppercase;
  line-height: 85%;
  font-size: 72px;
  background: -webkit-linear-gradient($color-primary, $color-secondary);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

h1 span {
  padding: 0 0 0 -2px;
}

p {
  max-width: 855px;
  margin: 0 0 40px;
  font-size: 18px;
  line-height: 180%;

  @media (min-width: 1140px) {
    font-size: 18px;
    line-height: 180%;
  }
}

strong {
  color: $color-secondary;
  // background: -webkit-linear-gradient($color-primary, $color-secondary 80%);
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
}

em {
  // border-bottom: 2px dashed $color-white;
  // background: linear-gradient($color-white, $color-white) padding-box,
  //   linear-gradient(to right, $color-primary, $color-secondary);
  // padding: 0 4px;
  // margin: 0 -4px;
  // text-decoration-line: underline;
  // text-decoration-style: wavy;
  // text-decoration-color: #e964ff;
  // text-underline-offset: 1px;
  // color: $color-secondary;
  // font-weight: bold;
  font-style: normal;
}
